<template>
  <section
    class="S-product-item__wrapper-bottom"
    :class="{'S-product-item__wrapper-similar': showSimilarPopup}"
  >
    <!-- 图片底部slot -->
    <slot name="imgBottom"></slot>

    <template v-if="!locateLabelsCtrl.lowerLeftVisible">
      <!-- 系列 -->
      <img
        v-if="showSeriesLogo && !plusSizeDefault"
        :src="baseCutImg(seriesLogo)"
        class="wrapper-bottom__series"
      />

      <!-- shein 大码兜底图-->
      <img
        v-else-if="showSeriesLogo && plusSizeDefault"
        :src="seriesLogo"
        role="contentinfo"
        class="wrapper-bottom__series"
      />

      <!-- ccc-tsp角标 -->
      <div
        v-else-if="imgBottomLeft"
        role="contentinfo"
        class="wrapper-bottom__badge"
        :style="imgBottomLeftBadgeStyle"
      >
        {{ imgBottomLeft.text }}
      </div>

      <!-- romwe 系列,大码 -->
      <section
        v-else-if="isRw && (seriesOrBrandLogoBottom || plusSize || romweoriginals) && locateLabelsCtrl.needShowOldLabel"
        role="contentinfo"
        class="wrapper-bottom__line"
      >
        <img
          v-if="seriesOrBrandLogoBottom"
          :src="baseCutImg(seriesOrBrandLogoBottom)"
        />
        <template v-else-if="plusSize">
          {{ plusSize }}
        </template>
        <template v-else-if="romweoriginals">
          {{ romweoriginals }}
        </template>
      </section>
    </template>

    <!-- 低价爆品 > 剩余库存 例子：only xx left > coming soon > 售罄 -->
    <template
      v-for="(infoItem, idx) in labelInfo"
      :key="idx"
    >
      <ImgBottomLabel
        :type="infoItem.type"
        :show-similar-popup="showSimilarPopup"
        :center="infoItem.center"
        :label-icon="infoItem.icon"
        :label-text="infoItem.text"
        :line-camp="infoItem.lineCamp"
      />
    </template>

    <!-- 腰带 -->
    <ImgBottomBeltLabel 
      v-if="beltPriceCtrl.beltLabel && !config.standardPrice"
      :belt-label="beltPriceCtrl.beltLabel"
      :estimated-price-info="estimatedPriceInfo"
      :discount-info="discountInfo"
    />

    <!-- 闪购  -->
    <!-- <div
        v-if="flashSale && !config.showNewFlashStyle"
        role="contentinfo"
        class="wrapper-bottom__flash-container"
      >
        <div class="wrapper-bottom__flash-process">
          <div :style="{ width: `${flashSale.saleProcess}%` }"></div>
        </div>
        <div class="wrapper-bottom__flash-message">
          <span>{{ flashSale.message.soldNum }}</span>
          <span>{{ flashSale.message.soldPercent }}</span>
        </div>
      </div> -->

    <!-- 多颜色图标展示 -->
    <section 
      v-if="showColorIcon"
      class="wrapper-bottom__colors"
    >
      <img
        :src="`${PUBLIC_CDN}/she_dist/images/colorMore-89e40835d5.png`"
        alt="图片"
      />
    </section>
  </section>
</template>

<script>
import { transformImg } from '@shein/common-function'
import ImgBottomLabel from './ImgBottomLabel'
import ImgBottomBeltLabel from './ImgBottomBeltLabel'
import 'public/src/icon/soldout.svg'

const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'ProductItemImgBottom',
  components: {
    ImgBottomLabel,
    ImgBottomBeltLabel
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    locateLabelsCtrl: {
      type: Object,
      default: () => ({
        needShowOldLabel: false,
        upperLeftVisible: false,
        upperRightVisible: false,
        lowerLeftVisible: false,
        lowerRightVisible: false,
      }),
    },
    imgBottomLeft: {
      type: [Object, null],
      default: null,
    },
    seriesLogo: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    flashSale: {
      type: [Object, Number, null],
      default: null,
    },
    plusSize: {
      type: String,
      default: '',
    },
    seriesOrBrandLogoBottom: {
      type: String,
      default: '',
    },
    romweoriginals: {
      type: String,
      default: '',
    },
    saleout: {
      type: [Object, null],
      default: null,
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isRw: {
      type: Boolean,
      default: false,
    },
    pretreatInfo: {
      type: Object,
      default: () => ({}),
    },
    beltPriceCtrl: {
      type: Object,
      default: () => ({}),
    },
    imgDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    estimatedPriceInfo: {
      type: Object,
      default: () => ({})
    },
    discountInfo: {
      type: Object,
      default: () => ({})
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  data() {
    return {
      PUBLIC_CDN,
    }
  },
  computed: {
    relatedColor () {
      return this.item.relatedColor || []
    },
    imgBottomLeftBadgeStyle() {
      return {
        color: this.imgBottomLeft?.fontColor || '',
        backgroundColor: this.imgBottomLeft?.backgroundColor || '',
      }
    },
    comingSoon() {
      return +this.goodsInfo?.new_product_unsale === 1
    },
    showSeriesLogo() {
      return !this.isRw && this.seriesLogo && this.config.showSeriesBrand && this.locateLabelsCtrl.needShowOldLabel
    },
    plusSizeDefault() {
      return this.pretreatInfo?.plusSizeDefault
    },
    stockLeft() {
      return this.pretreatInfo?.stockLeft
    },
    // shein 库存逻辑
    showStockLeft() {
      return this.stockLeft && this.config.showLeftStockShein
    },
    showColorIcon() {
      return this.relatedColor.length > 0 && this.config.showMultiColorIcon
    },
    saleOutText() {
      return this.saleout && this.saleout.show && this.saleout.message
    },
    labelInfo() {
      const showBestDeal = this.beltPriceCtrl.showBestDeal
      const followLabelVisible = this.beltPriceCtrl.followLabelVisible
      const beltInfo = {
        type: ['orange', '12PX'],
        icon: 'sh_pc_sui_icon_lowprice_12px_fff',
        text: showBestDeal ? this.language.SHEIN_KEY_PC_25736 : this.language.SHEIN_KEY_PC_27061,
        lineCamp: showBestDeal ? 2 : 1,
        center: true
      }
      const stockLeftInfo = {
        type: ['black', '16PX'],
        text: this.stockLeft,
        center: true
      }
      // 保留款 > 剩余库存 > coming soon > 售罄
      // 保留款 > 跟价款
      if (showBestDeal) {
        return [beltInfo]
      }
      if (followLabelVisible && this.showStockLeft) {
        return [stockLeftInfo, beltInfo]
      }
      if (followLabelVisible) {
        return [beltInfo]
      }
      if (this.showStockLeft) {
        return [stockLeftInfo]
      } else if (this.saleOutText) {
        const info = {
          type: ['black', '16PX'],
          text: this.saleOutText,
          center: true
        }
        if (this.showSimilarPopup) {
          info.icon = 'sh_pc_sui_icon_soldout_36px_fff'
        }
        if (this.comingSoon) {
          info.text = this.language.SHEIN_KEY_PC_17424
        }

        return [info]
      }

      return []
    },
  },
  methods: {
    transformImg,
    baseCutImg(imgUrl) {
      if (!this?.cutImg) {
        return this.transformImg({ img: imgUrl })
      }
      return this.cutImg(imgUrl, Number(this.imgDesignWidth), {})
    }
  }
}
</script>

<style lang="less">
.S-product-item {
  &__wrapper-similar {
    top: 0;
  }
  &__wrapper-bottom {
    position: absolute;
    bottom: 0;
    .left(0);
    .right(0);
    .wrapper-bottom {
      &__series {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
      }

      &__badge {
        display: inline-block;
        min-width: 48px;
        line-height: 22px;
        padding: 0 5px;
        .margin-l(6px);
        margin-bottom: 6px;
        text-align: center;
        font-size: 12px;
      }

      &__colors {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 13%;
        max-width: 25px;
        z-index: 1;

        img {
          width: 100%;
        }
      }

      // &__flash-container {
      //   position: relative;
      //   height: 40px;
      //   padding: 10px 15px 0;
      //   box-sizing: border-box;
      //   .txt-l();
      //   background: rgba(0, 0, 0, .3) /*rw: rgba(255, 255, 255, .3)*/;
      // }

      // &__flash-process {
      //   width: 100%;
      //   height: 8px;
      //   margin-bottom: 2px;
      //   background: #FFF;

      //   & > div {
      //     height: 100%;
      //     background: @sui_color_flash;
      //   }
      // }

      // &__flash-message {
      //   font-size: 12px;
      //   line-height: 14px;
      //   color: #FFF /*rw: #222*/;
      //   .flexbox();
      //   .space-between();
      // }

      &__line {
        width: 100%;
        background: rgba(153, 153, 153, 0.6);
        text-align: center;
        color: #fff;
        font-size: 12px;

        /*sh:begin*/
        height: 22px;
        line-height: 22px;
        /*rw:begin*/
        height: 25px;
        line-height: 25px;
        background: rgba(255, 255, 255, 0.6);
        color: #333;

        & > img {
          max-width: 100%;
          max-height: 100%;
          vertical-align: baseline;
        }
      }
    }
  }
}
</style>
