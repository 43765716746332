<template>
  <div 
    v-if="labelText && !showSimilarPopup"
    class="S-product-item__imgbottom-label"
    :class="{
      'S-product-item__imgbottom-label_orange': type[0] == 'orange',
      'S-product-item__imgbottom-label_black': type[0] == 'black',
      'S-product-item__imgbottom-label_16PX': type[1] == '16PX',
      'S-product-item__imgbottom-label_12PX': type[1] == '12PX',
      'S-product-item__imgbottom-label_center': center,
    }"
  >
    <i 
      v-if="labelIcon"
      :style="{marginRight: '4px'}"
      :class="['sh_pc_sui_icon', labelIcon]"
    ></i>
    <span 
      class="imgbottom-label__text"
      :class="{
        'imgbottom-label__text_two': lineCamp == 2,
        'imgbottom-label__text_16PX': type[1] == '16PX',
        'imgbottom-label__text_12PX': type[1] == '12PX',
      }"
    >{{ labelText }}</span>
  </div>
  <div
    v-else
    class="S-product-item__imgbottom-similar"
  >
    <i 
      v-if="labelIcon"
      :class="['sh_pc_sui_icon', labelIcon]"
    ></i>
    <span 
      class="imgbottom-label__text"
      :class="{
        'imgbottom-label__text_two': lineCamp == 2,
        'imgbottom-label__text_16PX': type[1] == '16PX',
        'imgbottom-label__text_12PX': type[1] == '12PX',
      }"
    >{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  name: 'ImgBottomLabel',
  props: {
    type: {
      type: Array,
      default: () => ['black', '16PX']
    },
    center: {
      type: Boolean,
      default: false
    },
    labelIcon: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      default: ''
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    lineCamp: {
      type: Number,
      default: 1
    }
  },
}
</script>

<style lang="less">
.S-product-item {
  &__imgbottom-label {
    .flexbox();
    .align-center();
    width: 100%;
    padding: 0 5px;
    &_orange {
      color: #fff;
      background: @sui_color_promo;
      opacity: 0.88;
    }
    &_black {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }
    &_16PX {
      line-height: 37px;
    }
    &_12PX {
      line-height: 20px;
    }
    &_center {
      .pack-center();
    }
    .imgbottom-label {
      &__text {
        .clamp(1);
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        
        &_two {
          .clamp(2);
        }
        &_16PX {
          font-size: 16px;
        }
        &_12PX {
          font-size: 12px;
          transform-origin: center left;
        }
      }
    }
  }
  &__imgbottom-similar {
    padding-top: 16px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .imgbottom-label__text {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
