<template>
  <div
    v-if="btnConfig.type === 'box'"
    :class="[
      'S-product-item__addbag-box',
      {
        'S-product-item__addbag-box_soldout': !!btnConfig.soldOut
      },
    ]"
  >
    {{ btnConfig.text }}
  </div>
  <i 
    v-else-if="btnConfig.type === 'icon'"
    class="S-product-item__addbag-icon sh_pc_sui_icon sh_pc_sui_icon_addtobag_16px_1_222"
    :class="{
      'small': size == 'small',
      'middle': size == 'middle'
    }"
  ></i>
</template>

<script>
export default { 
  name: 'ProductItemAddBagIcon', 
  props: {
    /**
     * 按钮配置
     * type 按钮类型 icon:图标 box:div方块
     * text 按钮文本
     */
    btnConfig: {
      type: Object,
      default: () => ({
        type: 'icon',
        text: '',
        soldOut: false,
      })
    },
    size: {
      type: String,
      default: 'small'
    }
  },
}
</script>

<style lang="less">
.S-product-item {
  &__addbag-icon {
    &.middle {
      width: 20px;
      height: 20px;
    }
  }
  &__addbag-box {
    font-size: 12px;
    padding: 5px 12px;
    border: 1px solid #000;
    line-height: 14px;
    font-weight: 700;
    white-space: nowrap;
    &_soldout {
      opacity: 0.6;
    }
  }
}
</style>
