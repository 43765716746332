<template>
  <div
    class="S-product-item__imgbottom-belt"
  >
    <template v-if="specialShapedBelt">
      <div class="imgbottom-specialshapedbelt__img-wrap special-shaped">
        <img :src="beltLabel.shapedImage" />
        <div class="imgbottom-specialshapedbelt__text-wrap">
          <div
            class="imgbottom-specialshapedbelt__text-save_wrap"
            :style="saveStyle"
          >
            <div class="imgbottom-specialshapedbelt__text-save">
              {{ save }}
            </div>
            <div
              class="imgbottom-specialshapedbelt__text-price"
              :style="{
                fontSize: priceFontSize
              }"
            >
              <i
                class="sh_pc_sui_icon sh_pc_sui_icon_lowprice_12px_fff"
                style="margin-right: 2px;"
              ></i>
              <span>{{ price }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="imgbottom-specialshapedbelt__img-wrap" 
        :style="styles"
      >
        <img :src="beltLabel.bgImage" />
        <div class="imgbottom-specialshapedbelt__text-wrap">
          <div
            class="imgbottom-specialshapedbelt__text-off"
            :style="textOffStyle"
          >
            {{ textOff }}
          </div>
        </div>
      </div>
    </template>

    <!-- 黑色星期五相关腰带 -->
    <template v-else-if="isBlackFridayBelt">
      <!-- 倒计时结束隐藏 -->
      <template v-if="countdownBelt && dynamicCountDown">
        <BlackFridayBelts
          :bg-img="beltLabel.transparentImage"
          :shaped-img="beltLabel.bgImage"
          :styles="blackFridayBeltStyles"
        >
          <span class="blackfridaybelts-content">
            <img 
              v-if="timerIcon" 
              ref="timerIconRef"
              :src="timerIcon" 
            />
            {{ dynamicCountDown }}
          </span>
        </BlackFridayBelts>
      </template>

      <template v-if="!countdownBelt">
        <BlackFridayBelts
          :bg-img="beltLabel.transparentImage"
          :shaped-img="beltLabel.bgImage"
          :styles="blackFridayBeltStyles"
        >
          <span class="blackfridaybelts-content">
            {{ blackFridayBeltText }}
          </span>
        </BlackFridayBelts>
      </template>
    </template>

    <template v-else>
      <!-- 倒计时结束隐藏 -->
      <template v-if="countdownBelt && dynamicCountDown">
        <img 
          :src="beltLabel.bgImage"
        />
        <div class="imgbottom-belt__text-wrap">
          <div 
            class="imgbottom-belt__text"
            :class="{
              'imgbottom-belt__text_half': half
            }"
            :style="styles"
          >
            <template v-if="labelText">
              {{ labelText }}
            </template>
            <img 
              v-if="timerIcon" 
              :src="timerIcon" 
              alt=""
            />
            <span>{{ dynamicCountDown }}</span>
          </div>
        </div>
      </template>

      <template v-if="!countdownBelt">
        <img 
          :src="beltLabel.bgImage"
        />
        <div class="imgbottom-belt__text-wrap">
          <div 
            class="imgbottom-belt__text"
            :class="{
              'imgbottom-belt__text_half': half
            }"
            :style="styles"
          >
            <template v-if="labelText">
              {{ labelText }}
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { timerEventBus, getDataTime } from '../utils/timerEventBus.js'
import BlackFridayBelts from './BlackFridayBelts.vue'

export default {
  components: {
    BlackFridayBelts
  },
  props: {
    beltLabel: {
      type: Object,
      default: () => ({})
    },
    half: {
      type: Boolean,
      default: false
    },
    estimatedPriceInfo: {
      type: Object,
      default: () => ({})
    },
    discountInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dynamicCountDown: null,
      currentTime: 0
    }
  },
  computed: {
    styles() {
      return {
        color: this.beltLabel.fontColor
      }
    },
    discountBelt() {
      return this.beltLabel.component == 'discount'
    },
    countdownBelt() {
      return ['countdown', 'countdownAbShaped'].includes(this.beltLabel.component)
    },
    specialShapedBelt() {
      return this.beltLabel.component == 'priceDiscount'
    },
    isBlackFridayBelt() {
      return ['countdownAbShaped', 'discountAbShaped', 'willSaveAbShaped', 'willSave'].includes(this.beltLabel.component)
    },
    // 折扣腰带文案
    labelText() {
      if (this.discountBelt && this.discountText) {
        return this.discountText
      }

      if (this.discountBelt) {
        return this.beltLabel.labelLang
      }

      return ''
    },
    timerIcon() {
      return this.beltLabel.icon
    },
    endTime() {
      return this.beltLabel.endTime || 0
      // return 1681565350000
    },
    // 金额折扣异形腰带 Save文案
    save() {
      return this.specialShapedBelt 
        ? this.beltLabel.saveLabelLang
        : ''
    },
    saveStyle() {
      return {
        color: this.beltLabel.saveFontColor
      }
    },
    // 金额折扣异形腰带 Save金额
    price() {
      if (this.specialShapedBelt && this.discountPriceText) {
        return this.discountPriceText
      }

      if (this.specialShapedBelt) {
        return this.beltLabel.savePrice
      }

      return ''
    },
    textOff() {
      if (this.specialShapedBelt && this.discountText) {
        return this.discountText
      }

      if (this.specialShapedBelt) {
        return this.beltLabel.labelLang
      }

      return ''
    },
    textOffStyle() {
      return {
        color: this.beltLabel.fontColor
      }
    },
    priceFontSize() {
      return '13px'
      // return this.fontSizeByChars(this.price.length)
    },
    blackFridayBeltStyles() {
      return {
        ...this.styles,
        fontSize: '13px'
        // fontSize: this.fontSizeByChars(this.blackFridayBeltText.length)
      }
    },
    blackFridayBeltText() {
      const isSaveLabelLang = ['willSaveAbShaped', 'willSave'].includes(this.beltLabel.component)
      const isDiscountLabelLang = ['discountAbShaped'].includes(this.beltLabel.component)

      if (this.isBlackFridayBelt && isSaveLabelLang) {

        if (this.discountPriceText) {
          return this.discountPriceText
        }
        
        return this.beltLabel.labelLang
      }

      if (this.isBlackFridayBelt && isDiscountLabelLang) {

        if (this.discountText) {
          return this.discountText
        }

        return this.beltLabel.labelLang
      }

      return ''
    },
    // 可省金额文案
    discountPriceText() {
      if (!this.beltLabel.estimatedLabelLang) return ''

      // discountPrice 的逻辑包含 到手价可省金额，付费会员可省金额，新型闪购可省金额，常规售价可省金额
      const discountPrice = this.discountInfo?.discountPrice || ''
      if (discountPrice) {
        return template(discountPrice, this.beltLabel.estimatedLabelLang)
      }

      return ''
    },
    // 折扣率文案
    discountText() {
      if (!this.beltLabel.estimatedLabelLang) return ''

      // unitDiscountString 的逻辑包含 到手价折扣率，付费会员折扣率，新型闪购折扣率，常规售价折扣率
      const unitDiscountString = this.discountInfo?.unitDiscountString || ''
      if (unitDiscountString) {
        return template(unitDiscountString?.replace('-', ''), this.beltLabel.estimatedLabelLang)
      }

      return ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.countdownBelt) return
      this.currentTime = this.beltLabel.currentTime || Date.now()
      // this.currentTime = 1681565340000
      if (this.endTime < this.currentTime) return
      this.timeId = timerEventBus.useState(this.handleTimeout)
    })
  },
  unmounted() {
    if (!this.countdownBelt) return
    timerEventBus.remove(this.timeId)
  },
  methods: {
    handleTimeout() {
      this.currentTime = this.currentTime + 1000 // 毫秒
      const seconds = this.endTime - this.currentTime
      this.dynamicCountDown = getDataTime(seconds)
      if (!this.dynamicCountDown) {
        timerEventBus.remove(this.timeId)
      }
    },
    /**
     * 目的: 通过缩小字号尽可能多的展示
     * 根据字符长度计算字体大小
     * @param {number} charsLength 
     * max: 12px
     * 
     * [0, 11]: 12px
     * [12, 13]: 11px
     * [14, 15]: 10px
     * [16, 17]: 9px
     * [18, 19]: 8px
     * 
     * min: 7px
     */
    fontSizeByChars(charsLength) {
      const chars = charsLength
      if (chars <= 11) return '12px'
      if (chars <= 13) return '11px'
      if (chars <= 15) return '10px'
      if (chars <= 17) return '9px'
      if (chars <= 19) return '8px'

      return '7px'
    }
  }
}
</script>

<style lang="less">
.S-product-item {
  &__imgbottom-belt {
    position: relative;
    width: 100%;
    >img {
      width: 100%;
      height: auto;
    }
    .imgbottom-belt {
      &__text-wrap {
        .flexbox();
        .align-center();
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
      }
      &__text {
        .text-overflow();
        // margin-left: 33.333%;
        margin-left: 50%;
        padding-right: 4px;
        line-height: 14px;
        font-size: 12px;
        flex: 1;
        text-align: center;
        &_half {
          margin-left: 50%;
        }

        >img {
          margin-right: 2px;
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }

        >span {
          vertical-align: middle;
        }
      }
    }
    
    .imgbottom-specialshapedbelt {
      &__img-wrap {
        position: relative;
        isolation: isolate;

        > img {
          width: 100%;
          height: auto;
        }

        &.special-shaped {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: @zindex-hack;
        }
      }

      &__text-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .flexbox();
      }

      &__text-save_wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 40%; // [1/3, 2/5]
        padding-top: 2px;
        padding-left: 4px;
        color: white;
        line-height: 1;
      }

      &__text-save {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 0;
        font-size: 9px;
        padding-top: 3px;
      }

      &__text-price {
        display: flex;
        align-items: center;
        height: 100%;

        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 700;
        }
      }

      &__text-off {
        flex: 1;
        .flexbox();
        align-items: center;
        justify-content: center;
        margin-left: 33.3%; // 1/3
        font-size: 13px;
        text-align: center;
      }
    } 
    
    /* 黑色星期五相关腰带 */
    .blackfridaybelts-content {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1;
    }
    .blackfridaybelts-content > img {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
    }
  }
}
</style>
