<template>
  <div
    class="normal-price-ctn__prices"
    :class="{ 
      'normal-price-ctn__prices_gap': showRightGap,
      'normal-price-ctn__prices_over-hidden': config.hideDiscountLabel,
      'normal-price-ctn__prices_discount': showDiscountLabel
    }"
    :style="{'height': config.hideDiscountLabel ? priceCtnStyle.height : ''}"
  >
    <!-- 促销icon集合 -->
    <template v-if="promotionIconList.length > 0 && !config.hidePromotionIcon">
      <i
        v-for="(promoIcon, i) in promotionIconList"
        :key="i"
        :class="[
          promoIcon,
          'sh_pc_sui_icon',
        ]"
      ></i>
    </template>
    <!-- 付费会员价-占用现售价的位置 -->
    <span 
      v-if="showSheinClubPrice"
      class="normal-price-ctn__sheinclub-price"
      :style="priceStyle.salePrice"
    >
      <CamelCasePrice
        :price="sheinClubPrice"
        :max-size="18"
        :min-size="12"
        :is-camel-case-price="config.isCamelCasePrice"
      />
    </span>
    <!-- 新型闪购价-占用现售价的位置 -->
    <span
      v-else-if="showNewFlashPrice"
      class="normal-price-ctn__flash-price"
      :style="priceStyle.salePrice"
    >
      <CamelCasePrice
        :price="newFlashPrice"
        :max-size="18"
        :min-size="12"
        :is-camel-case-price="config.isCamelCasePrice || config.showSheinappwordsize"
      />
    </span>
    <!-- 分人群专区闪购价 -->
    <span 
      v-else-if="flashZonePriceWithSymbol"
      class="normal-price-ctn__flash-price"
      :style="priceStyle.salePrice"
    >
      <CamelCasePrice
        :price="flashZonePrice"
        :max-size="18"
        :min-size="12"
        :is-camel-case-price="config.isCamelCasePrice || config.showSheinappwordsize"
      />
    </span>
    <!-- 售价 -->
    <span
      v-else-if="salePrice.amountWithSymbol"
      role="contentinfo"
      :aria-label="language.SHEIN_KEY_PC_15808 + salePrice.amountWithSymbol"
      class="normal-price-ctn__sale-price"
      :class="{ 
        'normal-price-ctn__sale-price_discount': isDiscount || (isPromoPrice && !showSmallSheinClubLabel && !showBigSheinClubLabel) || showSellerLimitedLabel,
      }"
      tabindex="0"
      :style="priceStyle.salePrice"
    >
      <CamelCasePrice
        :price="salePrice"
        :max-size="18"
        :min-size="12"
        :is-camel-case-price="config.isCamelCasePrice || config.showSheinappwordsize"
      />
    </span>
    <!-- 零售价 -->
    <span
      v-if="showRetailPrice"
      role="contentinfo"
      :aria-label="language.SHEIN_KEY_PC_17962 + retailPrice.amountWithSymbol"
      class="normal-price-ctn__retail-price"
      tabindex="0"
      :style="priceStyle.retailPrice"
    >
      {{ retailPrice.amountWithSymbol }}
    </span>
    <!-- 付费会员折扣标签 - 小号的 -->
    <ProductItemSheinClubDiscount
      v-if="showSmallSheinClubLabel"
      :discount-value="sheinClubDiscountValue"
      :public-cdn="publicCdn"
      type="small"
    />
    <!-- 折扣标签 -->
    <DiscountLabel 
      v-else-if="showDiscount"
      class="normal-price-ctn__discount-label"
      :type="showNewDiscountLabel && !isRw ? 'newDiscount' : 'discount'"
      :discount-info="discountInfo"
    />
    <!-- 到手价标签 -->
    <ProductItemEstimatedTag 
      v-if="showEstimatedTag"
      :estimated-price-with-symbol="estimatedPriceWithSymbol"
      :threshold-price-with-symbol="thresholdPriceWithSymbol"
      :satisfied="satisfied"
      :language="language"
      :config="config"
      :show-a-b-price="showABPrice"
    />
  </div>
</template>

<script>
import DiscountLabel from './DiscountLabel'
import CamelCasePrice from 'public/src/pages/components/CamelCasePrice.vue'
import ProductItemSheinClubDiscount from './ProductItemSheinClubDiscount'
import ProductItemEstimatedTag from './ProductItemEstimatedTag'
export default {
  name: 'ProductItemNormalPrice',
  components: {
    DiscountLabel,
    CamelCasePrice,
    ProductItemSheinClubDiscount,
    ProductItemEstimatedTag
  },
  props: {
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    showRightGap: {
      type: Boolean,
      default: false,
    },
    pretreatInfo: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    newFlashPromotion: {
      type: Object,
      default: () => ({}),
    },
    salePrice: {
      type: Object,
      default: () => ({}),
    },
    retailPrice: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    showNewDiscountLabel: {
      type: Boolean,
      default: false
    },
    isRw: {
      type: Boolean,
      default: false
    },
    publicCdn: {
      type: String,
      default: '',
    },
    sheinClubDiscountValue: {
      type: [String, Number],
      default: '',
    },
    sheinClubPrice: {
      type: Object,
      default: () => ({})
    },
    priceCtnStyle: {
      type: Object,
      default: () => ({}),
    },
    // 小号的付费会员折扣标签
    showSmallSheinClubLabel: {
      type: Boolean,
      default: false  
    },
    // 大号的付费会员折扣标签
    showBigSheinClubLabel: {
      type: Boolean,
      default: false  
    },
    estimatedPriceInfo: {
      type: Object,
      default: () => ({})
    },
    discountInfo: {
      type: Object,
      default: () => ({}),
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /** the icon next to price **/
    promotionIconList() {
      let list = []
      !this.config.standardPrice &&
      this.pretreatInfo.mixPromotionInfo &&
        this.pretreatInfo.mixPromotionInfo.forEach((i) => {
          if (!i.icon) return
          // app专属图标无需展示
          if (i.typeId !== 8) {
            if (i.typeId == 10) {
              // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
              if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && this.flashZonePriceWithSymbol)) {
                list.push(i.icon)
              }
              return
            }
            list.push(i.icon)
          }
        })
      const current = list.length ? [list[0]] : []
      return current
    },
    newFlashPrice() {
      return this.newFlashPromotion?.price || {}
    },
    showNewFlashPrice() {
      return (
        this.config.showNewFlashPriceOnSale &&
        this.newFlashPrice.amountWithSymbol
      )
    },
    showSheinClubPrice() {
      return this.config.showSheinClubPriceOnSale && this.sheinClubPrice?.amountWithSymbol
    },
    isDiscount() {
      const salePriceAmount = Number(this.salePrice.amount)
      const retailPriceAmount = Number(this.retailPrice.amount)
      const newFlashPriceAmount = Number(this.newFlashPrice.amount)
      const flashZonePriceAmount = Number(this.flashZonePrice.amount)
      // 展示付费会员价时 不展示划线价
      return this.showSheinClubPrice 
        ? false
        : this.showNewFlashPrice 
          ? retailPriceAmount > newFlashPriceAmount
          : flashZonePriceAmount > salePriceAmount || retailPriceAmount > salePriceAmount
    },
    showRetailPrice() {
      return !this.config.standardPrice && this.retailPrice.amountWithSymbol && !this.config.hideRetailPrice
        ? this.isDiscount
        : false
    },
    priceStyle() {
      const style = {
        salePrice: {},
        retailPrice: {},
      }
      const { salePriceFontSize, lineThroughPriceFontSize, salePriceColor } = this.config.style || {}

      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)
      lineThroughPriceFontSize && (style.retailPrice.fontSize = lineThroughPriceFontSize)
      if (this.showRetailPrice) {
        salePriceColor && (style.salePrice.color = salePriceColor)
      }

      if(this.config.standardPrice) {
        style.salePrice.color = '#222'
      }

      return style
    },
    showDiscountLabel() {
      return this.config.showDiscountLabelAfterPrice && !this.config.standardPrice
    },
    showDiscount() {
      return this.showDiscountLabel && !this.soldOut && this.discountInfo?.show
    },
    // 到手价
    estimatedPriceWithSymbol () {
      return this.estimatedPriceInfo?.estimatedPrice?.amountWithSymbol && !this.config.standardPrice
    },
    // 是否是到手价，旧商卡不接入ab价，只有是到手价时才展示到手价标签
    isEstimatedPrice () {
      return this.estimatedPriceInfo?.isEstimatedPrice && !this.config.standardPrice
    },
    showABPrice() {
      return this.estimatedPriceInfo?.showABPrice && !this.config.standardPrice
    },
    // 门槛价
    thresholdPriceWithSymbol() {
      return this.estimatedPriceInfo?.thresholdPrice?.amountWithSymbol
    },
    satisfied() {
      return this.estimatedPriceInfo?.satisfied
    },
    showEstimatedTag() {
      return !this.config.standardPrice && !this.showBigSheinClubLabel && (this.config.showEstimatedPrice || this.config.showEstimatedPriceReachThreshold || this.showABPrice) && this.isEstimatedPrice
        ? this.satisfied === 1
          ? this.estimatedPriceWithSymbol
          : this.estimatedPriceWithSymbol && this.thresholdPriceWithSymbol
        : null
    },
    flashZonePrice() {
      return this.goodsInfo.flashZonePromotion?.price || {}
    },
    flashZonePriceWithSymbol() {
      return this.flashZonePrice?.amountWithSymbol || ''
    },
    // 限时直降活动，固定显示红色价格
    showSellerLimitedLabel() {
      return this.pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)
    },
  },
}
</script>

<style lang="less">
.normal-price-ctn {
  &__prices {
    .flexbox();
    .align-center();
    
    flex-wrap: wrap;
    &_gap {
      padding-right: 10px;
    }
    /* stylelint-disable selector-class-pattern */
    .sh_pc_sui_icon_apponly_20px_promo,
    .sh_pc_sui_icon_time_16px_promo {
      margin-right: 4px;
    }
    &_discount {
      line-height: 21px;
      .normal-price-ctn__sheinclub-price,
      .normal-price-ctn__flash-price,
      .normal-price-ctn__sale-price {
        font-size: 16px;
      }
      .normal-price-ctn__retail-price {
        margin-left: 2px;
      }
    }
    &_over-hidden {
      height: 18px;
      line-height: 18px;
      overflow: hidden;
    }
    .shein-club-discount_small {
      margin-right: 4px;
      margin-top: 0;
    }
  }
  &__sheinclub-price,
  &__flash-price,
  &__sale-price,
  &__retail-price,
  &__discount-label {
    margin-right: 4px;
  }
  &__sheinclub-price {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: @sui_color_club_rosegold_dark1;
  }
  &__flash-price {
    .margin-r(4px);
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: @sui_color_discount;
  }
  &__sale-price {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: #222;
    &_discount {
      color: @sui_color_discount;
    }
  }
  &__retail-price {
    display: inline-block;
    font-size: 12px;
    color: @sui_color_gray_light1;
    text-decoration: line-through;
  }
}
</style>
