<template>
  <a
    ref="attrLink"
    :href="forbidJumpDetail ? 'javascript:void(0);' : goodsDetailUrl"
    class="product-item-decisionattr"
    :class="{ 'product-item-decisionattr__disabled': forbidJumpDetail }"
    @click="handleTextClick"
  >
    <div
      class="product-item-decisionattr__content"
      :class="wrapperCls"
    >
      {{ currText }}
    </div>
  </a>
</template>
<script>
/**
 * @file 决策属性组件
 */

export default {
  name: 'ItemDecisionAttr',
  props: {
    forbidJumpDetail: {
      type: Boolean,
      default: false,
    },
    goodsDetailUrl: {
      type: String,
      default: '',
    },
    attrList: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({})
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click-attr'],
  computed: {
    currText () {
      return this.attrList.map(item => item.name).join(' | ')
    },
    wrapperCls () {
      return {
        'product-item-decisionattr__with-title': this.titleVisible,
      }
    },
  },
  methods: {
    handleTextClick (event) {
      if (this.forbidJumpDetail) {
        return
      }
      this.$emit('click-attr', { event, target: this.$refs.attrLink })
    },
  },
}
</script>

<style lang="less" scoped>
.product-item-decisionattr {
  text-decoration: none;
  
  &__content {
    margin-top: 8px;
    .clamp(1);
    font-size: 11px;
    line-height: 12.65px;
    color: #767676;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }

  &__disabled {
    cursor: default;
  }

  &__with-title {
    margin-top: 4px;
  }
}
</style>
