<template>
  <div
    class="common-star"
    :class="{ 'large-star': lg, 'clickable-star': clickable }"
  >
    <span
      @mouseleave="resetCurrentValue"
    >
      <!-- 不要换行格式化, 否则展示会有空格 -->
      <Icon
        v-for="n in rankData.full"
        :key="n"
        :index="n"
        class="common-star__item common-star__icon"
        :name="startIconName('full')"
        size="16px"
        :custom-style="{ verticalAlign: 'none' }"
        @mousemove="setCurrentValue(n)"
        @click="selectValue(n)"
      />
      <Icon
        v-for="n in rankData.one"
        :key="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('one')"
        size="16px"
        :index="rankData.full + n"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />
      <Icon
        v-for="n in rankData.two"
        :key="rankData.full + n"
        :index="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('two')"
        size="16px"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />
      <Icon
        v-for="n in rankData.three"
        :key="rankData.full + n"
        class="common-star__item common-star__icon"
        :name="startIconName('three')"
        size="16px"
        :index="rankData.full + n"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + n)"
        @click="selectValue(rankData.full + n)"
      />
      <Icon
        v-for="n in rankData.empty"
        :key="rankData.full + rankData.three + rankData.two + rankData.one + n"
        class="common-star__item common-star__icon"
        :index="
          rankData.full + rankData.three + rankData.two + rankData.one + n
        "
        :name="startIconName('empty')"
        size="16px"
        :custom-style="{ verticalAlign: 'none'}"
        @mousemove="setCurrentValue(rankData.full + rankData.three + rankData.two + rankData.one + n)"
        @click="selectValue(rankData.full + rankData.three + rankData.two + rankData.one + n)"
      />
    </span>
    <span 
      v-if="showTxt" 
      class="star-txt"
    >{{
      currentValue ? parseFloat(currentValue).toFixed(1) : 0
    }}</span>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  components: { Icon },
  props: {
    modelValue: { default: 5 },
    lg: { default: false },
    clickable: { default: false },
    showTxt: { default: false },
  },
  data: function () {
    return {
      // currentValue: 0,
      hoverIndex: -1,
    }
  },
  computed: {
    currentValue() {
      return isNaN(this.modelValue) ? 5 : parseFloat(this.modelValue)
    },
    rankData: function () {
      var full = Math.floor(this.currentValue)
      var one = 0, two = 0, three = 0
      var decimal = Number((this.currentValue + '').split('.')[1])
      if (decimal > 0) {
        if (decimal <= 3) {
          one = 1
        } else if (decimal <= 6) {
          two = 1
        } else if (decimal <= 9) {
          three = 1
        }
      }
      var empty = 5 - (full + one + two + three)
      return {
        full: full,
        one,
        two,
        three,
        empty: empty,
      }
    },
    value: {
      get (){
        return this.modelValue
      },
      set (val){
        this.$emit('input', parseInt(val))
        this.$emit('update:modelValue', parseInt(val))
      }
    }
  },
  methods: {
    startIconName(type) {
      if (type == 'full') {
        return 'sui_icon_star_5_16px_1'
      }
      if (type == 'one') {
        return 'sui_icon_star_2_16px_1'
      }
      if (type == 'two') {
        return 'sui_icon_star_3_16px_1'
      }
      if (type == 'three') {
        return 'sui_icon_star_4_16px_1'
      }
      if (type == 'empty') {
        return 'sui_icon_star_1_16px_1'
      }
    },
    setCurrentValue: function (index) {
      if (!this.clickable) {
        return
      }
      if (index) {
        this.currentValue = parseInt(index)
        this.hoverIndex = parseInt(index)
      }
    },
    resetCurrentValue: function () {
      if (!this.clickable) {
        return
      }
      this.currentValue = this.value
      this.hoverIndex = -1
    },
    selectValue: function (index) {
      if (!this.clickable) {
        return
      }
      if (index) {
        this.$emit('input', parseInt(index))
        this.$emit('update:modelValue', parseInt(index))
      }
    },
  },
  emits: ['input', 'update:modelValue'],
}
</script>
<style lang="less">
.common-star {
    display: inline-block;
    &.large-star {
      .star-txt{
        font-size: 16px;
      }
    }
    &.clickable-star {
        cursor: pointer;
    }
    &__item {
      display: inline-block;
      color: @sui_color_honor !important;  /* stylelint-disable-line declaration-no-important */
      & + .common-star__item {
        .margin-l(3px);
      }
    }
    &__icon {
      transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
    }
    .star-txt{
        display: inline-block;
        .margin-l(10px);
    }
}
</style>
