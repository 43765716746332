import { getLocateLabelsAna } from '../analysis/locateLabelsAna'

// wiki: pageId=1186400824 增加，缓存反馈弹窗里面的数据
export const getFeedbackRecDataInSession = (goodsId) => {
  const data = JSON.parse(sessionStorage.getItem(`FEEDBACK_REC_DATA_KEY-${goodsId}`)) || null
  return data
}
// wiki: pageId=1186400824 增加，缓存反馈弹窗里面的数据
export const setFeedbackRecDataInSession = (goodsId, data = {}) => {
  const needDeleteValue = Object.keys(sessionStorage)?.filter((key)=>key.includes('FEEDBACK_REC_DATA_KEY')) || []
  needDeleteValue?.length && needDeleteValue?.forEach(key=> {
    sessionStorage.removeItem(key) // 存之前先清掉，防止缓存爆掉
  })
  sessionStorage.setItem(`FEEDBACK_REC_DATA_KEY-${goodsId}`, JSON.stringify(data))
}

/**
 * 是否是专题页
 * @returns {boolean}
 */
const isActivityPage = () => {
  return !!gbCommonInfo?.isActivity
}

export const isIE = () => {
  if (
    !!window.ActiveXObject ||
    'ActiveXObject' in window ||
    window.navigator.userAgent.indexOf('Edg') > -1
  ) {
    return true
  } else {
    return false
  }
}

export const controlItemLabels = (locateLabels, {
  config,
  discountInfo,
  flashSale,
}) => {
  const isFlashSale = !config.hideDiscountBadge && discountInfo && flashSale && config.style?.discountLabel === 'flashSale'

  // 专题页跟闪购需要展示品牌、系列、大促角标的旧逻辑
  const needShowOldLabel = isActivityPage() || isFlashSale
  const locateLabelSet = new Set(Object.keys(locateLabels || {}).filter(key => Object.keys(locateLabels[key] || {}).length))

  if (isFlashSale) {
    // 闪购优先级最高
    locateLabelSet.delete('UPPER_LEFT_LABEL')
  }

  const locateLabelsAna = getLocateLabelsAna(locateLabels, locateLabelSet)

  return {
    needShowOldLabel,
    locateLabelsAna,
    visible: locateLabelSet.size > 0,
    upperLeftVisible: locateLabelSet.has('UPPER_LEFT_LABEL'),
    upperRightVisible: locateLabelSet.has('UPPER_RIGHT_LABEL'),
    lowerLeftVisible: locateLabelSet.has('LOWER_LEFT_LABEL'),
    lowerRightVisible: locateLabelSet.has('LOWER_RIGHT_LABEL'),
  }
}

export const TRIGGER_EVENT_MAP = {
  'AddCarFailFavSuccess': '1',
  'AddCarFailFavFail': '2',
  'AddCarSuccessFavSuccess': '3',
  'AddCarSuccessFavFail': '4',
  'NotJudged': '5',
}

/**
 * @description abt解析给到推荐的ruleId
 * @param {*} listAbtResult abtList
 * @param {*} triggerEvent 触发事件
 * @returns 
 */
export const feedbackAbtToRuleId = (listAbtResult, triggerEvent) => {
  const ruleId = {}
  const index = TRIGGER_EVENT_MAP[triggerEvent]
  const abt = listAbtResult?.RealtimeFeedbackJson?.p || {}
  
  Object.keys(abt).forEach((key) => { 
    const arr = abt[key].split('&')

    if (arr.length <= 1) {
      ruleId[key] = abt[key]
    } else {
      arr.forEach(str => {
        const [k, v] = str.split('=')
        if (k === index) {
          ruleId[key] = v
        }
      })
    }
  })

  return ruleId
}

/**
 * 腰带与价格之间的控制逻辑
 * @param {Object} config abt相关的配置
 * @param {Object} param1 
 * @returns {{
*  beltLabel: false | Object;
*  showBestDeal: boolean;
*  followLabelVisible: boolean;
*  isPromoPrice: boolean;
* }}
*/
export const controlBeltPrice = (config, {
  isSoldOut = false,
  pretreatInfo,
}) => {
  const { showBeltLabel } = config || {}
  const beltLabel = showBeltLabel && !isSoldOut && pretreatInfo?.beltLabel
  const showBestDeal = !!pretreatInfo?.showBestDeal // 保留款腰带
  let followLabelVisible = pretreatInfo.followLabelVisible && !isSoldOut // 跟价款腰带
  let isPromoPrice = false // 价格是否展示促销状态

  if (beltLabel || showBestDeal) {
    // 保留款腰带>CCC配置的促销腰带>跟价款腰带
    followLabelVisible = false
  }
  if (followLabelVisible || showBestDeal) {
    isPromoPrice = true
  }

  return {
    beltLabel,
    showBestDeal,
    followLabelVisible,
    isPromoPrice,
  }
}
