<template>
  <div class="S-product-item__wrapper-upper-left">
    <!-- 图片左上角slot -->
    <slot name="imgTopLeft"></slot>
    <!-- 闪购促销折扣% -->
    <template v-if="!config.hideDiscountBadge && !config.standardPrice">
      <div
        v-if="showDiscount && flashSaleLabel"
        v-ada="{ level: adaLevel, pos: [index, 0] }"
        role="contentinfo"
        class="wrapper-upper-left__flashsale notranslate"
      >
        <i class="sh_pc_sui_icon sh_pc_sui_icon_flashsale_24px"></i>
        <span class="wrapper-upper-left__flashsale-percent">{{
          discountInfo.unitDiscountString
        }}</span>
      </div>
      <!-- 普通促销折扣% -->
      <DiscountLabel 
        v-else-if="showDiscount && !soldOut"
        class="wrapper-upper-left__discount-label"
        :discount-info="discountInfo"
        :type="discountLabelType"
      />
    </template>
    <!-- ccc-tsp角标 -->
    <div
      v-if="(config.hideDiscountBadge || !discountInfo) && imgUpperLeft"
      :style="imgUpperLeftBadgeStyle"
      class="wrapper-upper-left__badge"
    >
      {{ imgUpperLeft.text }}
    </div>
    <div
      v-else-if="showHotTag === 1"
      class="wrapper-upper-left__badge wrapper-upper-left__hot"
    >
      {{ language.SHEIN_KEY_PC_26829 || 'Hot' }}
    </div>
  </div>
</template>

<script>
import DiscountLabel from './DiscountLabel'

export default {
  name: 'ProductItemImgTopLeft',
  components: {
    DiscountLabel
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    goodsInfo: {
      type: Object,
      defualt: () => ({}),
    },
    discountInfo: {
      type: Object,
      default: () => ({}),
    },
    flashSale: {
      type: [Object, Number, null],
      default: null,
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
    imgUpperLeft: {
      type: [Object, null],
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    showHotTag: {
      type: Number, // TSP标签服务获取是否展示hot标
      default: 0
    },
    constantData: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    imgUpperLeftBadgeStyle() {
      return {
        color: this.imgUpperLeft?.fontColor || '',
        backgroundColor: this.imgUpperLeft?.backgroundColor || '',
      }
    },
    flashSaleLabel() {
      return this.flashSale && this.config.style?.discountLabel == 'flashSale'
    },
    discountLabelType() {
      let type = 'default'

      if (this.discountInfo.newFlashDiscount) {
        type = 'promo'
      }

      if (this.flashSale) {
        type = 'flash'
      }

      return type
    },
    showDiscount() {
      return !this.config.hideDiscountBadge && this.discountInfo?.show && !this.config.standardPrice
    }
  },
}
</script>

<style lang="less">
.S-product-item__wrapper-upper-left {
  position: absolute;
  left: 0 /* rtl: ignore */;
  top: 0;
  font-size: 12px;

  .product-item__third-part-label {
    background-color: #333;
    color: #fff;
    padding: 0 2px;
    margin-top: 4px;
    line-height: 1.35;
  }

  .wrapper-upper-left {
    &__badge {
      position: absolute;
      left: 0 /* rtl: ignore */;
      height: 22px;
      line-height: 22px;
      padding: 0 8px;
      white-space: nowrap;
      /*sh:begin*/
      top: 6px;
      /*rw:begin*/
      top: 0;
      font-family: 'Adieu';
    }
    &__hot {
      background: #FA6338;
      color:#FFF;
    }
    &__origin-discount {
      display: block;
      text-decoration: line-through;
      opacity: 0.8;
      direction: ltr /*rtl:ignore*/;
    }
    &__flashsale {
      text-align: center;
      padding: 10px 6px 6px;
      background: @sui_color_flash;
      /*sh:begin*/
      color: #222;
      /*rw:begin*/
      color: #fff;
      font-family: 'Adieu';
    }
    &__flashsale-percent {
      display: block;
      font-weight: bold;
      font-size: 16px;
      direction: ltr /* rtl:ignore */;
    }
    &__discount-label {
      position: absolute;
      left: 0 /*rtl:ignore*/;
      /*sh:begin*/
      top: 6px;
      /*rw:begin*/
      top: 0;
      font-family: 'Adieu';
    }
  }
}
</style>
