<template>
  <!-- 满足门槛 -->
  <div 
    v-if="showEstimatedPriceUpThreshold && estimatedPriceLangSplit"
    class="product-item__reach-estimated"
  >
    <i class="sh_pc_sui_icon sh_pc_sui_icon_coupon_discount_16px_fa6338"></i>
    <span 
      v-if="estimatedPriceLangSplit[0]"
      class="estimated-price__text"
    >
      {{ estimatedPriceLangSplit[0].trim() }}
    </span>
    <span class="estimated-price__price">{{ estimatedPriceWithSymbol }}</span> 
    <span 
      v-if="estimatedPriceLangSplit[1]"
      class="estimated-price__text"
    >{{ estimatedPriceLangSplit[1].trim() }}</span>
  </div>
  <!-- 不满足门槛 -->
  <div 
    v-else-if="showEstimatedPriceBelowThreshold"
    class="product-item__noreach-estimated"
  >
    <span class="estimated-price__price">{{ estimatedPriceWithSymbol }}</span>
    <span class="estimated-price__text">{{ template(thresholdPriceWithSymbol, language.SHEIN_KEY_PC_26184) }}</span>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

export default {
  name: 'ProductItemEstimatedTag',
  props: {
    config: {
      type: Object,
      default: () => ({})
    }, 
    language: {
      type: Object,
      default: () => ({})
    }, 
    estimatedPriceWithSymbol: {
      type: String,
      default: ''
    },
    thresholdPriceWithSymbol: {
      type: String,
      default: ''
    }, 
    satisfied: {
      type: Number,
      default: -1
    },
    showABPrice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      template
    }
  },
  computed: {
    // 满足门槛
    showEstimatedPriceUpThreshold() {
      return this.satisfied === 1 && this.estimatedPriceWithSymbol && (this.config.showEstimatedPriceReachThreshold || this.config.showEstimatedPrice || this.showABPrice)
    },
    // 不满足门槛
    showEstimatedPriceBelowThreshold() {
      return this.satisfied === 0 && this.estimatedPriceWithSymbol && this.thresholdPriceWithSymbol && this.config.showEstimatedPrice
    },
    estimatedPriceLangSplit() {
      return this.language.SHEIN_KEY_PC_26183.split('{0}')
    }
  }
}
</script>

<style lang="less">
.product-item {
  &__reach-estimated,
  &__noreach-estimated {
    display: inline-flex;
    .align-center();
    max-width: 100%;
    height: 16px;
    line-height: 16px;
    background: #FFECE9;
    color: @sui_color_discount;
    border-radius: 2px;
    box-sizing: content-box;
    .estimated-price {
      &__text {
        font-size: 12px;
        .text-overflow();
      }
      &__price {
        font-size: 14px;
        font-weight: bold;
        margin-left: 2px;
      }
    }
  }
  &__reach-estimated {
    position: relative;
    padding-left: 20px;
    padding: 2px 3px 2px 20px;
    /* stylelint-disable-next-line selector-class-pattern */
    .sh_pc_sui_icon_coupon_discount_16px_fa6338 {
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__noreach-estimated {
    padding: 3px 4px;
    .estimated-price {
      &__text {
        line-height: 12px;
        margin-left: 4px;
        padding-left: 4px;
        border-left: 1px solid @sui_color_discount;
      }
      &__price {
        margin-left: 0;
      }
    }
  }
}
</style>
