<template>
  <section
    ref="colorsContainer"
    role="radiogroup"
    aria-label="color"
    class="S-product-item__relatecolor-container"
    :class="{
      'S-product-item__relatecolor-container-new': config.showGoodsSpace
    }"
  >
    <s-radio
      v-for="(relate, colorindex) in [...relateColorList].slice(0, showRelateColor)"
      :key="relate.goods_id"
      v-model="selectedRelateColor"
      :label="Number(relate.goods_id)"
      :custom="true"
      gap="8px"
      :aria-label="relate.goods_name"
      :data-sku="relate.goods_sn"
      :data-id="relate.goods_id"
      :data-spu="relate.productRelationID"
      :index="index"
      :data-position-index="Number(colorindex + 1)"
      :data-page="goodsInfo.thisPage"
      :data-rec_mark="relate.ext && relate.ext.rec_mark"
      :data-extra_mark="relate.ext && relate.ext.extra_mark"
      :data-us-price="relate.salePrice && relate.salePrice.usdAmount"
      :data-us-origin-price="relate.retailPrice && relate.retailPrice.usdAmount"
      :data-show-exclusive-price="relate.exclusivePromotionPrice"
      :data-video="relate.video_url"
      :data-spu-img="spuImgObj.spuImg ? (spuImgObj._showSpuImg ? 'spupic_1' : 'spupic_0') : ''"
      :data-mall_tag_code="`${relate.pretreatInfo && relate.pretreatInfo.mallTagsInfo && relate.pretreatInfo.mallTagsInfo.mall_tags || ''}_${relate.mall_code || '-'}`"
      :data-store_code="relate.store_code"
      :data-sold-out="relate.pretreatInfo && relate.pretreatInfo.saleout && relate.pretreatInfo.saleout.show"
      :data-series-brand="relate.pretreatInfo && relate.pretreatInfo.seriesOrBrandAnalysis"
      :tabindex="adaLevel"
      @mouseleave="relateColorMouseleave"
      @mouseenter="relateColorHover(relate.goods_id)"
      @focus="relateColorHover(relate.goods_id)"
      @click="handleRelateColorClick({ relateColorInfo: relate })"
      @keydown.enter.space="handleRelateColorClick({ relateColorInfo: relate })"
    >
      <section class="S-product-item__relatecolor">
        <div
          :data-background-image="transformImg({ img: relate.goods_color_image })"
          :src="lazyImgSquare"
          class="S-product-item__relatecolor-inner lazyload"
        >
          <span
            v-if="relate.stock == 0"
            class="S-product-item__relatecolor-soldout"
          ></span>
        </div>
      </section>
    </s-radio>
    <s-popover
      v-if="showRelateColor > 0 && showRelateColor < relateColorList.length"
      placemen="bottom"
      trigger="hover"
      :prop-style="{ padding: '12px 8px' }"
      @before-open="showPopover"
    >
      <section
        v-if="showHiddenColor"
        style="font-size: 0"
      >
        <s-radio
          v-for="(relate, colorindex) in [...relateColorList].slice(
            showRelateColor
          )"
          :key="relate.goods_id"
          v-model="selectedRelateColor"
          :label="Number(relate.goods_id)"
          :custom="true"
          :gap="
            Number(colorindex + 1) ==
              [...relateColorList].slice(showRelateColor).length
              ? '0px'
              : '8px'
          "
          :data-sku="relate.goods_sn"
          :data-id="relate.goods_id"
          :data-spu="relate.productRelationID"
          :index="index"
          :data-position-index="Number(colorindex + 1)"
          :data-page="goodsInfo.thisPage"
          :data-rec_mark="relate.ext && relate.ext.rec_mark"
          :data-extra_mark="relate.ext && relate.ext.extra_mark"
          :data-us-price="relate.salePrice && relate.salePrice.usdAmount"
          :data-us-origin-price="relate.retailPrice && relate.retailPrice.usdAmount"
          :data-show-exclusive-price="relate.exclusivePromotionPrice"
          :data-video="relate.video_url"
          :data-spu-img="spuImgObj.spuImg ? (spuImgObj._showSpuImg ? 'spupic_1' : 'spupic_0') : ''"
          :data-mall_tag_code="`${relate.pretreatInfo && relate.pretreatInfo.mallTagsInfo && relate.pretreatInfo.mallTagsInfo.mall_tags || ''}_${relate.mall_code || '-'}`"
          :data-store_code="relate.store_code"
          :data-sold-out="relate.pretreatInfo && relate.pretreatInfo.saleout && relate.pretreatInfo.saleout.show"
          :data-series-brand="relate.pretreatInfo && relate.pretreatInfo.seriesOrBrandAnalysis"
          @mouseenter="relateColorHover(relate.goods_id)"
          @mouseleave="relateColorMouseleave"
          @click="handleRelateColorClick({ relateColorInfo: relate })"
        >
          <section class="S-product-item__relatecolor">
            <div
              :data-background-image="transformImg({ img: relate.goods_color_image })"
              :src="lazyImgSquare"
              class="S-product-item__relatecolor-inner lazyload"
            >
              <span
                v-if="relate.stock == 0"
                class="S-product-item__relatecolor-soldout"
              ></span>
            </div>
          </section>
        </s-radio>
      </section>
      <section v-else></section>
      <template #reference>
        <span
          class="S-product-item__relatecolor-showmore"
          @mouseenter="showmorecolor"
        >+{{ hideColorNum }}</span>
      </template>
    </s-popover>
  </section>
</template>

<script>
import 'public/src/icon/more.svg'
import { debounce, transformImg } from '@shein/common-function'
import { SRadio } from '@shein-aidc/sui-radio'
import { SPopover } from '@shein-aidc/sui-popover'
import { expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'ProductItemColors',
  directives: { expose },
  components: {
    SRadio,
    SPopover
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    relateColorList: {
      type: Array,
      default: () => [],
    },
    selectedRelateColor: {
      type: [String, Number],
      default: '',
    },
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    lazyImgSquare: {
      type: String,
      default: '',
    },
    spuImgObj: {
      type: Object,
      default: () => ( {} )
    }
  },
  data() {
    return {
      showRelateColor: 0, // 需要展示出来的色块，剩余色块 '...' hover展开显示
      showHiddenColor: false,
    }
  },
  computed: {
    relatedColor() {
      return this.item.relatedColor || []
    },
    hideColorNum() {
      return this.relateColorList.length - this.showRelateColor
    },
  },
  mounted() {
    this.computeRelateColorShowNum()

    window.addEventListener(
      'resize',
      debounce({
        func: () => {
          this.computeRelateColorShowNum()
        },
        wait: 1000,
      })
    )
  },
  methods: {
    relateColorMouseleave() {
      window.clearTimeout(window.relateColorHoverSetTimeout)
    },
    relateColorHover(goodsId) {
      if (this.config.disableMultiColorHover) return
      if (Number(goodsId) == this.selectedRelateColor) return
      window.clearTimeout(window.relateColorHoverSetTimeout)
      window.relateColorHoverSetTimeout = window.setTimeout(() => {
        this.$emit('relateColorHover', Number(goodsId), Number(this.item.goods_id))
      }, 300)
    },
    handleRelateColorClick({ relateColorInfo = null } = {}) {
      if (this.config.disableMultiColorJump) {
        this.$emit('clickMultiColor', relateColorInfo)
        return
      }
      if (!relateColorInfo?.detail_url) return
      location.href = relateColorInfo.detail_url
    },
    showmorecolor(e) {
      e && e.target && e.target.click()
    },
    showPopover() {
      this.showHiddenColor = true
    },
    computeRelateColorShowNum() {
      let infoContainer = this.$refs.colorsContainer
      let infoWidth = infoContainer && infoContainer.clientWidth

      this.showRelateColor = Math.floor(infoWidth / (22 + 8)) - 1
    },
    transformImg
  },
  emits: ['relateColorHover', 'clickMultiColor'],
}
</script>

<style lang="less">
.S-product-item {
  &__relatecolor-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    .svgicon {
      vertical-align: text-bottom;
      text-align: center;
    }
  }
  &__relatecolor-container-new {
    margin-top: 6px;
  }
  &__relatecolor {
    position: relative;
    width: 22px;
    height: 22px;
    padding: 1px;
    border-radius: 100%;
    border: 1px solid transparent;

    &:hover {
      border-color: #222;
      padding: 1px;
    }
  }

  &__relatecolor-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
  }
  &__relatecolor-soldout {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(-45deg);
    transform-origin: center;
    display: block;
    width: 100%;
    height: 2px;
    padding: 0 1px 0;
    background: rgba(153, 153, 153, 0.7);
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: -1px;
      .left(0);
      background: rgba(255, 255, 255, 0.6);
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      .left(0);
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &__relatecolor-showmore {
    cursor: pointer;
    font-size: 13px;
    color: #666;
  }
  /** 多颜色 **/
}
.sui-radio__checked {
  .S-product-item__relatecolor {
    border-color: #222;
    padding: 2px;
  }
}
</style>
