<template>
  <div
    v-if="goodsName"
    class="S-product-item__name"
    :class="{
      'S-product-item__name-new': config.showGoodsSpace
    }"
  >
    <a
      ref="namelink"
      :href="forbidJumpDetail ? 'javascript:void(0);' : goodsDetailUrl"
      class="S-product-item__link"
      :class="{ 
        'S-product-item__link_jump': !forbidJumpDetail,
        'S-product-item__link_two': config.showGoodsNameTwoLine
      }"
      :aria-label="goodsName"
      :tabindex="adaLevel"
      @click="handleClickGoodsName"
      @keydown.enter.stop="handleClickGoodsName"
    >
      <label
        v-if="frontTitle"
        class="S-product-item__badge"
      >
        {{ frontTitle.text }}
      </label>
      <label
        v-else-if="showHotTag === 2"
        class="S-product-item__badge S-product-item__hot"
      >
        {{ language.SHEIN_KEY_PC_26829 || 'Hot' }}
      </label>
      {{ goodsName }}
    </a>
    <div @click.stop="$emit('clickAddBagIcon')">
      <!-- 加车icon -->
      <SProductItemAddBagIcon
        v-if="config.showAddBagAfterGoodsName"
      />
    </div>
  </div>
</template>

<script>
import SProductItemAddBagIcon from './ProductItemAddBagIcon'

export default {
  name: 'ProductItemGoodsName',
  components: {
    SProductItemAddBagIcon,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    goodsDetailUrl: {
      type: String,
      default: '',
    },
    goodsName: {
      type: String,
      default: '',
    },
    forbidJumpDetail: {
      type: Boolean,
      default: false,
    },
    frontTitle: {
      type: Object,
      default: () => ({})
    },
    showHotTag: {
      type: Number, // TSP标签服务获取是否展示hot标
      default: 0
    },
  },
  computed: {
    // frontTitleBadgeStyle() {
    //   return {
    //     color: this.frontTitle?.fontColor || '',
    //     backgroundColor: this.frontTitle?.backgroundColor || '',
    //   }
    // },
  },
  methods: {
    handleClickGoodsName(event) {
      this.$emit('clickGoodsName', { event, target: this.$refs.namelink })
    },
  },
  emits: ['clickGoodsName', 'clickAddBagIcon'],
}
</script>

<style lang="less">
.S-product-item {
  &__badge {
    display: inline-block;
    height: 13px;
    line-height: 13px;
    padding: 0 3px;
    margin-right: 4px;
    background: #EDFFEE;
    color: #3CBD45;
    font-weight: bold;
  }
  &__hot {
    background: #FA6338;
    color:#FFF;
  }
  &__name {
    .flexbox();
    .align-center();
    padding-top: 10px;
    line-height: 14px;
    font-size: 12px;
    box-sizing: content-box;
    .small {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  &__name-new {
    padding-top: 8px;
  }
  &__link {
    .clamp(1);
    width: 100%;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #000;
      text-decoration: none;
    }
    &_jump {
      &:hover {
        cursor: pointer;
        color: #222;
        text-decoration: underline;
      }
    }
    &_two {
      .clamp(2);
    }
  }
}
</style>
