class TimerEventBus {
  constructor() {
    this.timerState = false 
    this.timeQueue = new Map()
  }

  useState(cb) {
    if (!this.timerState) {
      this.timerState = true
      changeTimeOut()
    }
    const key = this.timeQueue.size
    this.timeQueue.set(key, cb)
    return key
  }

  remove(key) {
    if (typeof key === 'undefined') return
    this.timeQueue.delete(key)
    this.timerState = !!this.timeQueue.size
  }
}

const timerBus = new TimerEventBus()

function changeTimeOut() {
  if (!timerBus.timerState) return
  setTimeout(() => {
    timerBus.timeQueue.forEach(c => c())
    changeTimeOut()
  }, 1000)
}


export const timerEventBus = timerBus

export function getDataTime(time) {
  if (time < 1) return
  // 北京时区
  const timeZone = 8 * 3600 * 1000
  time = time - timeZone
  const d = new Date(time)
  const t = (i) => { return (i < 10 ? '0' : '') + i }
  const day = parseInt((time + timeZone) / ( 60 * 60 * 24 * 1000))
  const hour = d.getHours()
  const minutes = d.getMinutes()
  const seconds = d.getSeconds()
  const str = `${t(hour) || (seconds && '00')}:${t(minutes) || (seconds && '00')}:${t(seconds)}`

  if (!day) return str
  return day + 'D ' + str
}
