import { daEventCenter } from 'public/src/services/eventCenter/index'

// 点击add to bag
const clickAddToBagAna = ({ target }) => {
  daEventCenter.triggerNotice({
    daId: '2-3-5', //click_goods_list_addcar
    target
  })
}

// 点击find similar按钮
const clickFindSimilarAna = ({ goodsInfo, config, soldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-3',
    extraData: {
      sku: goodsInfo.goods_sn,
      goods_id: goodsInfo.goods_id,
      activeFrom: config.from,
      findsimilar_method: soldOut ? 'SoldOut' : 'ClickMore', //  售空 SoldOut，无售空 ClickMore
      isOutOfStock: soldOut ? 1 : 0,
    },
  })
}

// 曝光find similar按钮
const exposeFindSimilarAna = ({ goodsInfo, config, soldOut }) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-2',
    extraData: {
      sku: goodsInfo.goods_sn,
      goods_id: goodsInfo.goods_id,
      activeFrom: config.from,
      isOutOfStock: soldOut ? 1 : 0,
    },
  })
}

// 曝光加组按钮
const exposeAddToBoardAna = ({ groupList }) => {
  daEventCenter.triggerNotice({
    daId: '1-4-1-8',
    extraData: { board_count: groupList.length ? 1 : 0 },
  })
}

export {
  clickAddToBagAna,
  clickFindSimilarAna,
  exposeFindSimilarAna,
  exposeAddToBoardAna
}
