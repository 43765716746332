<template>
  <div class="S-product-item__price">
    <!-- 新型闪购标签（新人，专属） -->
    <div 
      v-if="config.showNewFlashLabelUpPrice && flashTypeText"
      class="new-flash-label"
    >
      {{ flashTypeText }}
    </div>
    <section
      class="normal-price-ctn"
      :class="{
        'normal-price-ctn__height-holder': showNormalPrice,
        'normal-price-ctn__big-margin': config.hideGoodsName,
        'normal-price-ctn__margin-new': config.showGoodsSpace
      }"
      :style="priceCtnStyle"
    >
      <slot name="price">
        <ProductItemNormalPrice
          v-if="showNormalPrice"
          :goods-info="goodsInfo"
          :show-right-gap="!!(showAddWishIcon || showAddBagIcon || showDelIcon)"
          :pretreat-info="pretreatInfo"
          :config="config"
          :new-flash-promotion="newFlashPromotion"
          :sale-price="salePrice"
          :retail-price="retailPrice"
          :language="language"
          :is-promo-price="isPromoPrice"
          :show-new-discount-label="showNewDiscountLabel"
          :is-rw="isRw"
          :public-cdn="publicCdn"
          :shein-club-discount-value="sheinClubDiscountValue"
          :shein-club-price="sheinClubPrice"
          :price-ctn-style="priceCtnStyle"
          :show-small-shein-club-label="showSmallSheinClubLabel"
          :show-big-shein-club-label="showBigSheinClubLabel"
          :estimated-price-info="estimatedPriceInfo"
          :discount-info="discountInfo"
          :sold-out="soldOut"
        />
      </slot>
      <!-- 收藏夹icon -->
      <div
        v-if="showAddWishIcon"
        role="button"
        :aria-label="`wishlist`"
        tabindex="0"
        class="normal-price-ctn__add-wishlist"
        :class="[activeWish ? 'normal-price-ctn__add-wishlist_added' : '']"
        @keydown.enter.stop="handleAddWishlist"
        @click.stop="handleAddWishlist"
      >
        <i
          v-show="isPlayWishlistAnimation !== false"
          class="normal-price-ctn__add-wishlist_normal sh_pc_sui_icon"
          :class="[
            activeWish
              ? 'sh_pc_sui_icon_save_completed_16px_main'
              : 'sh_pc_sui_icon_save_16px_1_222',
          ]"
        ></i>
        <i
          v-show="isPlayWishlistAnimation !== false"
          class="sh_pc_sui_icon sh_pc_sui_icon_save_completed_16px_main"
        ></i>
      </div>
      <!-- 加车icon -->
      <div 
        v-else-if="showAddBagIcon" 
        class="normal-price-ctn__add-bag"
        :class="{ 
          'normal-price-ctn__add-bag_middle': addBagIconStyle.size == 'middle',
          'normal-price-ctn__add-bag-box': addBagBtnConfig.type === 'box',
        }"
        :style="addBagIconStyle"
        @click="handleAddBagIconClick"
      >
        <SProductItemAddBagIcon
          :size="addBagIconStyle.size"
          :btn-config="addBagBtnConfig"
        />
      </div>
      <!-- 删除icon -->
      <ProductItemDeleteIcon 
        v-else-if="config.showDelete && showNormalPrice"
        :hovering="hovering"
        :show-select="showSelect"
        @delete-item="$emit('deleteItem')"
      />
    </section>
    <!-- 新型闪购标签（新人，专属）-->
    <NewFlashLabel
      v-if="showNewFlash"
      ref="newFlashLabelEl"
      :flash-type-text="flashTypeText"
    />
    <!-- 付费会员折扣标签 - 大号的 -->
    <ProductItemSheinClubDiscount
      v-if="showBigSheinClubLabel"
      :discount-value="sheinClubDiscountValue"
      :public-cdn="publicCdn"
    />
    <!-- 付费会员价格 -->
    <div 
      v-if="config.showSheinClubPrice" 
      class="shein-club"
    >
      <div class="shein-club__prime-price-ctn">
        <img
          class="shein-club__logo"
          :src="`${publicCdn}/she_dist/images/pay_vip/sui_icon_paidmember@5x-adf3888936.png`"
        />
        <div
          v-if="sheinClubPrice.amountWithSymbol"
          class="shein-club__prime-price"
        >
          {{ sheinClubPrice.amountWithSymbol }}
        </div>
      </div>
      <div 
        v-if="sheinClubRetailPrice" 
        class="shein-club__sale-price"
      >
        {{ sheinClubRetailPrice }}
      </div>
    </div>
    <!-- vip price -->
    <section
      v-if="!isRw && exclusivePromotionPrice && !config.showLastPrice"
      class="vip-price"
      tabindex="0"
    >
      <span>{{ exclusivePromotionPrice }}</span>
      <i
        :class="[
          'vip-price__icon',
          cssRight ? 'vip-price__icon_ar' : '',
        ]"
      ></i>
    </section>
    <!-- rw vip price -->
    <RwVipPrice
      v-if="isRw && exclusivePromotionPrice && !config.showLastPrice"
      class="rw-vip-price"
      :price="exclusivePromotionPrice"
    />
    <!-- rw vip price -->

    <div v-if="config.showLastPrice">
      <span class="last-price">
        {{ 
          exclusivePromotionPrice 
            || sheinClubPrice?.amountWithSymbol
            || sheinClubRetailPrice
            || newFlashPromotion?.amountWithSymbol
            || salePrice?.amountWithSymbol
            || retailPrice?.amountWithSymbol
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { exposeAddToBoardAna } from '../analysis'
import { campaignContent } from 'activity/common/mixin'
import { composeCampaign } from 'activity/common/toLogin'
import { isEmpty } from 'lodash'
import { SMessage } from '@shein-aidc/sui-message'
import RwVipPrice from './RomweVipPrice.vue'
import SProductItemAddBagIcon from './ProductItemAddBagIcon'
import ProductItemDeleteIcon from './ProductItemDeleteIcon'
import ProductItemSheinClubDiscount from './ProductItemSheinClubDiscount'
import ProductItemNormalPrice from './ProductItemNormalPrice'
import { userModule } from 'public/src/pages/common/user_module.js'

export default {
  name: 'ProductItemPrices',
  components: {
    RwVipPrice,
    SProductItemAddBagIcon,
    ProductItemDeleteIcon,
    ProductItemSheinClubDiscount,
    ProductItemNormalPrice,
    NewFlashLabel: defineAsyncComponent(() => import('./NewFlashLabel')),
  },
  props: {
    isRw: {
      type: Boolean,
      default: false,
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    publicCdn: {
      type: String,
      default: '',
    },
    // 专题会注入该对象
    propData: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    pretreatInfo: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    salePrice: {
      type: Object,
      default: () => ({}),
    },
    retailPrice: {
      type: Object,
      default: () => ({}),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    hovering: {
      type: Boolean,
      default: false,
    },
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    goodsItemInfo: {
      type: Object,
      default: () => ({}),
    },
    // 商品项数据
    item: {
      type: Object,
      default: () => ({})
    },
    // 尺码信息
    sizeInfo: {
      type: Object,
      default: () => ({}),
    },
    wishStatus: {
      type: Boolean,
      default: false,
    },
    showSpuImg: {
      type: Boolean,
      default: false,
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    showNewDiscountLabel: {
      type: Boolean,
      default: false
    },
    estimatedPriceInfo: {
      type: Object,
      default: () => ({})
    },
    discountInfo: {
      type: Object,
      default: () => ({}),
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // wishlist animation class
      wishlistAnimation: null,
      isPlayWishlistAnimation: null,
      isAddWishilist: [],
    }
  },
  computed: {
    goodsId() {
      return Number(this.goodsInfo?.goods_id)
    },
    goodsSn() {
      return this.goodsInfo?.goods_sn
    },
    // addWishlistLoading () {
    //   return this.pretreatInfo?.addWishlistLoading || false
    // },
    addWishlistResult() {
      return this.pretreatInfo?.addWishlistResult
    },
    exclusivePromotion() {
      return this.goodsInfo?.exclusivePromotionPrice || {}
    },
    exclusivePromotionPrice() {
      return this.exclusivePromotion?.amountWithSymbol
    },
    isWish() {
      return typeof this.addWishlistResult !== 'undefined'
        ? this.addWishlistResult
        : this.wishStatus
    },
    activeWish() {
      return typeof this.addWishlistResult !== 'undefined'
        ? this.addWishlistResult && this.isPlayWishlistAnimation
        : this.wishStatus
    },
    showAddBagIcon() {
      return this.config.showAddBagAfterGoodsPrice && this.salePrice?.amountWithSymbol
    },
    showAddWishIcon() {
      return this.config.showAddWishlist && !this.showSelect && this.salePrice?.amountWithSymbol
    },
    addBagBtnConfig() {
      let { percent = 0 } = this?.item?.salePercent || {}
      const _config = this.config.addBagBtnConfig || {
        type: 'icon'
      }
      let text = '', soldOut = false
      if (percent < 100) {
        text = _config.notSoldOutWord || ''
        soldOut = false
      } else {
        text = _config.soldOutWord || ''
        soldOut = true
      }
      return {
        ..._config,
        text,
        soldOut
      }
    },
    showDelIcon() {
      return this.config.showDelete && this.hovering && !this.showSelect
    },
    sheinClubPromotionInfo() {
      return this.goodsInfo.sheinClubPromotionInfo || {}
    },
    sheinClubDiscountValue() {
      return this.sheinClubPromotionInfo.discountValue || ''
    },
    showSmallSheinClubLabel() {
      // return !!(this.sheinClubDiscountValue && this.config.showSheinClubDiscountValueAfterPrice && !this.config.standardPrice)
      return false
    },
    showBigSheinClubLabel() {
      // return !!(this.config.showSheinClubDiscountValue && this.sheinClubDiscountValue && !this.config.standardPrice)
      return false
    },
    sheinClubPrice() {
      return this.sheinClubPromotionInfo.price || {}
    },
    sheinClubRetailPrice() {
      const { amount: sheinClubPriceAmount } = this.sheinClubPrice || {}
      const {
        amount: retailPriceAmount,
        amountWithSymbol: retailAmountWithSymbol,
      } = this.retailPrice || {}
      return Number(sheinClubPriceAmount) < Number(retailPriceAmount)
        ? retailAmountWithSymbol
        : ''
    },
    priceCtnStyle() {
      return this.config.style?.priceCtn || {}
    },
    showNormalPrice() {
      if (!this.config.hideNormalPrices) {
        if (this.config.showOnlyNewFlashPrice && this.newFlashPromotion)
          return false

        return true
      }
      return false
    },
    newFlashPromotion() {
      return this.pretreatInfo?.newFlashPromotion || null
    },
    flashTypeText() {
      return this.newFlashPromotion?.flashTypeText
    },
    showNewFlash() {
      return this.config.showNewFlashLabelBelowPrice && this.flashTypeText && !this.config.standardPrice
    },
    addBagIconStyle() {
      const { size, backgroundColor } = this.config?.style?.addBagIcon || {}

      return { size, backgroundColor }
    }
  },
  watch: {
    addWishlistResult: {
      immediate: true,
      handler(val) {
        if (!val || this.isAddWishilist.indexOf(this.goodsId) > -1) return
        this.isAddWishilist.push(this.goodsId)
        if (!this.wishlistAnimation) {
          this.isPlayWishlistAnimation = true
        } else {
          this.isPlayWishlistAnimation = false
          this.wishlistAnimation
            .play({
              container: this.$el.querySelector('.normal-price-ctn__add-wishlist'),
            })
            .then(() => {
              this.isPlayWishlistAnimation = true
            })
        }
      },
    },
  },
  methods: {
    async handleAddWishlist(fn) {
      if (
        this.addWishlistLoading ||
        (!this.config.ableDeleteWishlist && this.isWish)
      )
        return

      this.addWishlistLoading = true

      if (this.config.ableDeleteWishlist && this.isWish) {
        this.cancelWish()
      } else {
        this.addWish(fn)
      }
    },
    // 取消收藏
    async cancelWish() {
      const result = await this.goodsItemInfo.cancelWish({
        goodsItem: this.goodsInfo,
        params: { goodsId: this.goodsId },
      })
      this.addWishlistLoading = false
      if (result.type == 'success') {
        this.isAddWishilist.splice(0, 1)
        this.$emit('cancelWish', this.goodsInfo)
      } else {
        SMessage({
          message: 'Failed, please try again later',
          type: 'warning',
        })
      }

      this.wishlistAnalysis('deleteWishAnalysis', result?.responseData || {}, 0)
    },
    // 收藏
    async addWish(fn) {
      import(
        'public/src/services/productRecommend/utils/wishlistAnimation'
      ).then(async (modules) => {
        this.wishlistAnimation = modules.default
        const result = await this.goodsItemInfo.addWish({
          goodsItem: this.goodsInfo,
          params: {
            goodsId: this.goodsId,
            goodsSn: this.goodsSn,
            sizeInfo: this.sizeInfo || {},
            mallCode: this.goodsInfo.mall_code,
          },
          config: this.config,
        })
        this.addWishlistLoading = false
        if (result.type === 'needLogin') {
          let loginParam = {
            cb: this.loginSucc,
            from: 'wishlist',
          }
          loginParam = this.shouldReportSensor(loginParam)
          SHEIN_LOGIN.show(loginParam)
        } else if (result.type === 'success') {
          if (typeof fn === 'function') { // fix: 登录成功后，收藏成功回调
            fn()
            return
          }
          this.$emit('addWishSucc', this.goodsInfo)

          // 加组提示曝光
          if (result['showWishlistGroup']) {
            const groupList = result.responseData?.addWishlistGroupResult.data?.info?.groupList || []
            sessionStorage.setItem('groupListData', JSON.stringify(groupList))
            // 埋点
            exposeAddToBoardAna({ groupList })
          }
        } else if (result.type === 'limit') {
          SMessage({
            message: result.tips,
            type: 'warning',
            offset: 164,
            duration: 3000,
          })
        }

        this.wishlistAnalysis(
          'addWishAnalysis',
          result?.responseData?.addWishlistResult || {},
          1
        )
      })
    },
    wishlistAnalysis(event, result, action) {
      this.$emit(event, {
        code: this.config.code,
        from: this.config.from,
        result,
        action,
        sku: this.goodsInfo.goods_sn,
        goods_id: this.goodsInfo.goods_id,
        detail: this.goodsInfo,
        index: this.goodsInfo.index,
        mall_code: this.goodsInfo.mall_code,
      })
    },
    loginSucc() {
      userModule.update_top_login()
      cart_module && cart_module.reload()
      this.handleAddWishlist(() => {
        // fix: 确保在登录成功并且收藏成功后刷新收藏页面
        if (location.pathname == '/user/wishlist') {
          location.reload()
          window.scrollTo(0, 0)
        }
        // 登陆成功以后刷新已更新券数据
        // TODO 待优化
        if (location.pathname.includes('/pdsearch')) {
          location.reload()
          window.scrollTo(0, 0)
        }
      })
    },
    shouldReportSensor(loginParam) {
      if (isEmpty(this.propData)) {
        return loginParam
      }
      const activity_sign = composeCampaign(campaignContent, this.propData)
      loginParam = Object.assign(loginParam, {
        sextra: activity_sign,
        gextra: activity_sign,
      })
      return loginParam
    },
    // 点击加车
    handleAddBagIconClick() {
      if (Boolean(this.addBagBtnConfig?.soldOut)) {
        return
      }
      this.$emit('clickAddBagIcon')
    },
  },
  emits: ['clickAddBagIcon', 'addWishSucc', 'deleteItem', 'deleteWishAnalysis', 'addWishAnalysis', 'cancelWish'],
}
</script>

<style lang="less">
@s3_exclusive: '/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png';
@s3_exclusive_ar: '/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png';
.S-product-item {
  &__price {
    .new-flash-label {
      font-size: 12px;
      line-height: 14px;
      margin: 4px 0 -2px;
      color: @sui_color_discount;
    }
    .normal-price-ctn {
      .flexbox();
      .space-between();
      .align-center();
      position: relative;
      &__height-holder {
        margin-top: 4px;
        line-height: 16px;
        min-height: 16px;
      }
      &__big-margin {
        margin-top: 10px;
      }
      &__margin-new {
        margin-top: 8px;
      }
      &__add-wishlist {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        cursor: pointer;
        z-index: 30; /* stylelint-disable-line declaration-property-value-blacklist */

        /* stylelint-disable selector-class-pattern */
        /* stylelint-disable-next-line selector-max-specificity */
        .sh_pc_sui_icon_save_completed_16px_main:not(.normal-price-ctn__add-wishlist_normal) {
          display: none;
          /*rw:begin*/
          color: @sui_color_main;
        }
        &:hover {
          /* stylelint-disable-next-line selector-max-specificity */
          .sh_pc_sui_icon_save_16px_1_222 {
            /*sh:begin*/
            transform: scale(1.1);
            /*rw:begin*/
            display: none;
          }
          .sh_pc_sui_icon_save_completed_16px_main:not(.normal-price-ctn__add-wishlist_normal) { /* stylelint-disable-line */
            /*rw:begin*/
            display: inline-block;
          }
        }
        &_added {
          &:hover {
            .sh_pc_sui_icon_save_completed_16px_main:not(.normal-price-ctn__add-wishlist_normal) { /* stylelint-disable-line */
              /*rw:begin*/
              display: none;
            }
          }
        }

        #j-recommend-item-wishlist-save-animate { /* stylelint-disable-line */
          /*sh:begin*/
          position: absolute;
          top: 0;
          .right(-1px);
          width: 18px;
          height: 18px;
          /*rw:begin*/
          position: absolute;
          top: -11px;
          .right(-12px);
          width: 38px;
          height: 38px;
        }
      }
      &__add-bag {
        .flexbox();
        .pack-center();
        .align-center();
        cursor: pointer;
        &_middle {
          width: 40px;
          height: 30px;
          border-radius: 33px;
        }
      }
      &__delete {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .shein-club {
      .flexbox();
      .align-center();
      flex-wrap: wrap;
      margin-top: 10px;
      &__prime-price-ctn {
        .flexbox();
        .align-center();
      }
      &__logo {
        width: 15px;
        margin-right: 3px;
      }
      &__prime-price {
        font-size: 14px;
        line-height: 16px;
        margin-right: 4px;
        color: #ffba97;
        font-weight: bold;
      }
      &__sale-price {
        font-size: 12px;
        line-height: 14px;
        color: @sui_color_gray_light1;
        text-decoration: line-through;
      }
    }
    .vip-price {
      margin-top: 4px;
      padding-bottom: 12px;
      height: 14px;
      .txt-l();

      > span {
        font-size: 12px;
        font-weight: bold;
        color: #a78a45;
      }

      &__icon {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 34px 10px;
        width: 34px;
        height: 10px;
        background-image: url(@s3_exclusive);

        &_ar {
          background-image: url(@s3_exclusive_ar);
          vertical-align: -1px;
        }
      }
    }
    .rw-vip-price {
      margin-top: 4px;
      padding-bottom: 4px;
    }

    .last-price {
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      transform: scale(0.84);
    }
  }
  &__new-flash {
    margin-top: 4px;
  }
}
</style>
