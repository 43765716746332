<template>
  <div class="S-product-item__rank">
    <rate-vue v-model="goodsInfo.commentInfo.comment_rank_average" />
    ({{ allCommentCountStr }})
  </div>
</template>

<script>
import rateVue from 'public/src/pages/components/rate'
export default {
  name: 'ProductItemRank',
  components: {
    rateVue,
  },
  props: {
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    // 展示的评论数
    allCommentCountStr() {
      const { goodsInfo = {} } = this
      const { commentInfo = {} } = goodsInfo || {}
      const { comment_num, comment_num_str, comment_fuzzy } = commentInfo
      if (comment_fuzzy) {
        return comment_num_str
      }
      return comment_num >= 10000 ? '9999+' : `${comment_num || 0}`
    },
  },
}
</script>

<style lang="less">
.S-product-item {
  &__rank {
    font-size: 12px;
  }
}
</style>
