<template>
  <div class="blackfridaybelts__wrapper">
    <!-- 有背景透明图: 异形黑五腰带 -->
    <template v-if="bgImg">
      <div class="blackfridaybelts-shaped">
        <img :src="shapedImg" />
      </div>
      <div class="blackfridaybelts-bg">
        <img :src="bgImg" />
        <div class="blackfridaybelts-bg__text-wrapper">
          <div
            class="blackfridaybelts-bg__text"
            :style="styles"
          >
            <slot>
              <span>{{ beltText }}</span>
            </slot>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="blackfridaybelts-bg">
        <img :src="shapedImg" />
        <div class="blackfridaybelts-bg__text-wrapper">
          <div
            class="blackfridaybelts-bg__text not-shaped"
            :style="styles"
          >
            <slot>
              <span>{{ beltText }}</span>
            </slot>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BlackFridayBelts',
  props: {
    bgImg: {
      type: String,
      default: ''
    },
    shapedImg: {
      type: String,
      default: ''
    },
    beltText: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less">
.blackfridaybelts__wrapper {
  position: relative;
  font-size: 0;

  .blackfridaybelts-shaped {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    > img {
      width: 100%;
      height: auto;
    }
  }

  .blackfridaybelts-bg {
    position: relative;
    > img {
      width: 100%;
      height: auto;
    }

    &__text-wrapper {
      font-size: 13px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
    }

    &__text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 33.3%; // 1/3
      text-align: center;
      overflow: hidden; // 限制文字溢出

      // > span {
      //   font-weight: 600;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      //   line-height: 1;
      // }
    }
    &__text.not-shaped {
      margin-left: 50%; // 1/2
    }
  }
}
</style>
