<template>
  <div
    v-show="showDelIcon"
    class="normal-price-ctn__delete"
    @click="$emit('deleteItem')"
  >
    <i class="sh_pc_sui_icon sh_pc_sui_icon_delete_16px_1_222"></i>
  </div>
</template>

<script>
export default {
  name: 'ProductItemDeleteIcon',
  props: {
    hovering: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDelIcon() {
      return this.hovering && !this.showSelect
    },
  },
  emits: ['deleteItem']
}
</script>

<style lang="less">
.normal-price-ctn {
  &__delete {
    cursor: pointer;
  }
}
</style>
