<template>
  <a
    ref="imgContainer"
    :class="[
      'S-product-item__img-container',
      'j-expose__product-item-img',
      {
        'S-product-item__img-container_mask': showImgMask
      },
    ]"
    :href="forbidJumpDetail ? 'javascript:void(0);' : goodsDetailUrl"
    tabindex="0"
    :aria-label="goodsName"
    :target="openTarget"
    @contextmenu="showMenu"
    @mouseenter="mainGoodsImgHover"
    @mouseleave="mainGoodsImgHoverLeave"
    @click="clickItem"
    @focus="mainGoodsImgFocus"
  >
    <!-- 主图 -->
    <CropImageContainer
      v-if="!relateColorInfo"
      :fixed-ratio="config?.fixedRatio || '3-4'"
      :img-src="mainImg"
      :is-support-crop-image="isSupportCropImage"
      :transform-img-props="{
        imgClassName: noFspClass ? void 0 : 'fsp-element',
        isFirstPage: noImgLazy,
        usePicture: false,
        loadImg: lazyImg,
        imgDesignWidth: productDesignWidth,
        alt: goodsName
      }"
    />
    <!-- 多颜色切换 首图不需要懒加载 -->
    <CropImageContainer
      v-else
      fixed-ratio="3-4"
      :is-support-crop-image="isSupportCropImage"
      :img-src="transformImgFn({ img: mainImg })"
      :transform-img-props="{
        isFirstPage: true,
        alt: goodsName
      }"
    />
    <ClientOnly>
      <!-- 二图 -->
      <div :class="['S-product-item__img-submain', showSecondImg ? 'image-fade-in' : 'image-fade-out']">
        <CropImageContainer
          v-if="secondImg"
          fixed-ratio="3-4"
          :is-support-crop-image="isSupportCropImage"
          :img-src="secondImg"
          :transform-img-props="{
            isFirstPage: false,
            usePicture: false,
            loadImg: lazyImg,
            imgDesignWidth: productDesignWidth,
            alt: goodsName
          }"
        />
      </div>
      <s-product-item-locate-labels
        v-if="locateLabelsCtrl.visible"
        :locate-labels="locateLabels"
        :img-design-width="productDesignWidth"
      />
      <!-- 图片右上角 品牌，收藏数，选择input -->
      <s-product-item-img-top-right
        ref="imgTopRight"
        :config="config"
        :pretreat-info="pretreatInfo"
        :locate-labels-ctrl="locateLabelsCtrl"
        :show-select="showSelect"
        :goods-info="goodsInfo"
        :select-status="selectStatus"
        :index="index"
        :promo-label-src="promoLabelSrc"
        :img-design-width="productDesignWidth"
        @select="handleSelect"
      />
    </ClientOnly>

    <!-- 底部横向 (左下角, 右下角，横向) -->
    <s-product-item-img-bottom
      :config="config"
      :locate-labels-ctrl="locateLabelsCtrl"
      :img-bottom-left="imgBottomLeft"
      :series-logo="seriesLogo"
      :index="index"
      :flash-sale="flashSale"
      :plus-size="plusSize"
      :series-or-brand-logo-bottom="seriesOrBrandLogoBottom"
      :romweoriginals="romweoriginals"
      :saleout="saleout"
      :show-similar-popup="showSimilarPopup"
      :goods-info="goodsInfo"
      :language="language"
      :is-rw="isRw"
      :item="item"
      :pretreat-info="pretreatInfo"
      :img-design-width="productDesignWidth"
      :belt-price-ctrl="beltPriceCtrl"
      :estimated-price-info="estimatedPriceInfo"
      :discount-info="discountInfo"
    >
      <!-- 图片底部slot -->
      <template #imgBottom>
        <slot name="imgBottom"></slot>
      </template>
    </s-product-item-img-bottom>
  </a>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { transformImg as transformImgFn } from '@shein/common-function' 
import SProductItemImgBottom from './ProductItemImgBottom.vue'// 修改组件引入方式，解决不贵组件腰带水合后元素丢失

export default {
  name: 'ProductItemImgs',
  inject: ['isSupportCropImage'],
  components: {
    ClientOnly,
    CropImageContainer,
    SProductItemLocateLabels: defineAsyncComponent(() => import('./ItemLocateLabels.vue')),
    SProductItemImgTopRight: defineAsyncComponent(
      () => import('./ProductItemImgTopRight')
    ),
    SProductItemImgBottom,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    pretreatInfo: {
      type: Object,
      default: () => ({}),
    },
    locateLabels: {
      type: Object,
      default: () => ({}),
    },
    locateLabelsCtrl: {
      type: Object,
      default: () => ({
        upperLeftVisible: false,
        upperRightVisible: false,
        lowerLeftVisible: false,
        lowerRightVisible: false,
      }),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    selectStatus: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isRw: {
      type: Boolean,
      default: false,
    },
    goodsInfo: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ( {} )
    },
    index: {
      type: Number,
      default: 0,
    },
    forbidJumpDetail: {
      type: Boolean,
      default: false,
    },
    relateColorInfo: {
      type: [Object, null],
      default: () => ({}),
    },
    lazyImg: {
      type: String,
      default: '',
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    // 窗口打开类型
    openTarget: {
      type: String,
      default: '_self',
    },
    showSpuImg: {
      type: Boolean,
      default: false,
    },
    goodsDetailUrl: {
      type: String,
      default: ''
    },
    beltPriceCtrl: {
      type: Object,
      default: () => ({}),
    },
    promoLabelSrc: {
      type: String,
      default: ''
    },
    productDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    // 展示图片蒙层
    showImgMask: {
      type: Boolean,
      default: false
    },
    estimatedPriceInfo: {
      type: Object,
      default: () => ({})
    },
    // 不使用图片懒加载
    noImgLazy: {
      type: Boolean,
      default: false,
    },
    discountInfo: {
      type: Object,
      default: () => ({})
    },
    noFspClass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSecondImg: false,
    }
  },
  computed: {
    spuImg() {
      return ((this.goodsInfo?.spu_image || [])[0]?.medium_image) || ''
    },
    mainImg() {
      return this.showSpuImg ? this.spuImg : this.goodsInfo?.goods_img || ''
    },
    // getCombinedInfo的detail_image是个对象数组，如果前置格式化数据，商品数量多时消耗过多性能，故在这里兼容一下取值
    secondImg() {
      return this.goodsInfo?.detail_image?.[0]?.medium_image || this.goodsInfo?.detail_image?.[0] || ''
    },
    /** 图片左下角角标 new top rated 内容其实是配置的，后期可以随意新增 */
    imgBottomLeft() {
      return (this.pretreatInfo && this.pretreatInfo.imgBottomLeft) || null
    },
    seriesLogo() {
      return (this.pretreatInfo && this.pretreatInfo.seriesLogo) || null
    },
    flashSale() {
      return this.pretreatInfo && this.pretreatInfo.flashsale
    },
    plusSize() {
      return (this.pretreatInfo && this.pretreatInfo.plusSize) || null
    },
    seriesOrBrandLogoBottom() {
      return (this.config.showSeriesBrand && this.pretreatInfo?.seriesOrBrandLogoBottom) || null
    },
    romweoriginals() {
      return (this.config.showSeriesBrand && this.pretreatInfo?.romweoriginals) || null
    },
    saleout() {
      return this.pretreatInfo?.saleout || { show: false }
    },
    goodsName() {
      return this.goodsInfo?.goods_name || ' '
    },
  },
  methods: {
    showMenu(e) {
      if (this.forbidJumpDetail) {
        e.preventDefault()
      }
    },
    mainGoodsImgHover() {
      if (this.showSpuImg) return

      window.clearTimeout(window.mainGoodsHoverSetTimeout)
      window.mainGoodsHoverSetTimeout = window.setTimeout(() => {
        this.secondImg && (this.showSecondImg = true)
      }, 300)
    },
    mainGoodsImgHoverLeave() {
      window.clearTimeout(window.mainGoodsHoverSetTimeout)
      this.secondImg && (this.showSecondImg = false)
    },
    clickItem(event) {
      if (this.showSelect) this.$refs.imgTopRight?.onChange()
      this.$emit('clickItem', { event, target: this.$refs.imgContainer })
    },
    // 处理选中
    handleSelect(payload) {
      this.$emit('select', payload)
    },
    // 焦点事件
    mainGoodsImgFocus() {
      this.$emit('focus')
    },
    transformImgFn
  },
  emits: ['clickItem', 'select', 'focus'],
}
</script>

<style lang="less">
.S-product-item {
  &__img-container {
    position: relative;
    display: inline-block;
    width: 100%;
  //   height: 0;
  //   padding-bottom: 133.082%;
    cursor: pointer;

  //   > img {
  //     width: 100%;
  //   }
    &_mask::before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      box-sizing: border-box;
      display: block;
      z-index: 1;
    }
  }
  // &__img-container_crop-120 {
  //   padding-bottom: 120%;

  //   >img {
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);
  //   }
  // }
  &__img-submain {
    position: absolute;
    top: 0;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0); /** 防止图片模糊 **/
    &.image-fade-out {
      transition: opacity 0.5s;
      opacity: 0;
    }
    &.image-fade-in {
      transition: opacity 0.5s;
      opacity: 1;
    }
  }
}
</style>
